import * as React from "react"
import { graphql, Link } from "gatsby"
import { Breadcrumb, Space, Typography } from "antd"
// import slugify from "@sindresorhus/slugify"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import { JsonDebugger } from "../components/json-debugger"
// import { MoreButton } from "../../../components/more-button"
import { ProductListing } from "../components/product-listing"
import { RichText } from "prismic-reactjs"
import PageContent from "../components/page-content"
import { htmlSerializer } from "../utils/html-serializer"
// import { title } from "../index.module.css"

const { Title } = Typography
export default function ProductTypeIndex({
  // data,
  // pageContext
  data: { products, productType } /* = {
    products: { nodes: null, pageInfo: { hasNextPage: false } },
    productType: 'Unknown'
  },*/,
  // pageContext: { data__shopify_product__product_type: productType },
}) {
  const productsList = products.nodes.sort((a, b) => {
    const aSort = a.data.product_sort || 0
    const bSort = b.data.product_sort || 0
    return bSort - aSort
  })
  return (
    <Layout>
      <Seo
        title={`Category: ${RichText.asText(
          productType?.data?.name?.richText
        )}`}
      />
      <PageContent>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/products/">Products</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {RichText.asText(productType?.data?.name?.richText)}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Space direction="vertical" style={{ minWidth: "100%" }}>
          <Title>{RichText.asText(productType?.data?.name?.richText)}</Title>
          <RichText
            render={productType?.data?.description?.richText}
            htmlSerializer={htmlSerializer}
          />
          <ProductListing products={productsList} />
        </Space>
        {/* <JsonDebugger json={{ productType }} /> */}
      </PageContent>
      {/* {products?.pageInfo?.hasNextPage && (
        <morebutton to={`/search?p=${slugify(producttype)}#more`}>
          more products
        </morebutton>
      )} */}
      {/* <JsonDebugger json={{ products, productsList }} /> */}
    </Layout>
  )
}
export const query = graphql`
  query CategoryProducts($data__product_type__uid: String!) {
    productType: prismicProductType(uid: { eq: $data__product_type__uid }) {
      url
      data {
        name {
          richText
        }
        description {
          richText
        }
      }
    }
    products: allPrismicProduct(
      filter: {
        data: { product_type: { uid: { eq: $data__product_type__uid } } }
      }
      #limit: 10
      sort: { fields: data___product_sort, order: DESC }
    ) {
      nodes {
        data {
          product_sort
        }
        ...ProductCard
      }
    }
  }
`

// export const query = graphql`
//   query($productType: String!) {
//     products: allShopifyProduct(
//       filter: { productType: { eq: $productType } }
//       sort: { fields: publishedAt, order: ASC }
//       limit: 24
//     ) {
//       nodes {
//         ...ProductCard
//       }
//       pageInfo {
//         hasNextPage
//       }
//     }
//   }
// `
